<template>
  <section class="w-full max-w-8xl m-auto flex flex-col justify-between items-center px-2 lg:px-4 pt-6 lg:py-16 space-y-11">
    <p class="text-lg font-bold text-lightgray-400 text-center">
      The world's most innovative companies use MattePaint
    </p>
    <div class="flex justify-evenly w-full flex-wrap items-center md:space-x-4">
      <img
        src="~/assets/img/home/logos/fusefx.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="Fuse FX"
      />
      <img
        src="~/assets/img/home/logos/DIGIC.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="DIGIC Pictures"
      />
      <img
        src="~/assets/img/home/logos/mpc.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="MPC"
      />
      <img
        src="~/assets/img/home/logos/framestore.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="framestore"
      />
      <img
        src="~/assets/img/home/logos/dneg.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="DNEG"
      />
      <img
        src="~/assets/img/home/logos/blur.png"
        class="h-8 md:h-11 mb-8 lg:mb-0 hidden md:flex"
        alt="Blur"
      />
    </div>
    <div class="hidden lg:flex justify-evenly w-full">
      <img
        src="~/assets/img/logos/millfilm.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="Mill Film"
      />
      <img
        src="~/assets/img/logos/mrx.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="MR.X"
      />
      <img
        src="~/assets/img/logos/technicolor.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="Technicolor"
      />
      <img
        src="~/assets/img/logos/scanlinevfx.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="Scanline VFX"
      />
      <img
        src="~/assets/img/logos/animallogic.png"
        class="h-8 md:h-11 mb-8 lg:mb-0"
        alt="Animal Logic"
      />
    </div>
  </section>
</template>